import React from 'react'
import HomeScreen from './HomeScreen'
const Home = () => {
  return (
    <div>
      {/* <HomeSlider/> */}
      <HomeScreen/>
    </div>
  )
}

export default Home